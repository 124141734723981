<template>
  <Modal v-model="isShow" title="支付配置" class-name="i-modal" width="700px">
    <Form
      :model="obj"
      class="modelViewFrom"
      ref="form"
      label-position="left"
      :label-width="80"
    >
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Name" label="名称">
            <Input type="text" v-model="obj.Name"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Type" label="类型">
            <Select clearable v-model="obj.Type" placeholder="支付类型">
              <Option
                v-for="item in PayConfigTypeEnum"
                :value="item.ID"
                :key="item.ID"
                >{{ item.CN }}</Option
              >
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Status" label="状态">
            <Select clearable v-model="obj.Status" placeholder="状态">
              <Option key="0" :value="0">禁用</Option>
              <Option key="1" :value="1">启用</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="usechannelArr" label="使用渠道">
            <i-select
              multiple
              v-model="usechannelArr"
              placeholder="使用渠道"
              :disabled="!isEdit && !isAdd"
            >
              <i-option :key="'30'" :value="'30'">苹果</i-option>
              <i-option :key="'20'" :value="'20'">安卓</i-option>
              <i-option :key="'10'" :value="'10'">H5</i-option>
              <i-option :key="'40'" :value="'40'">小程序</i-option>
            </i-select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Name" label="开始时间">
            <DatePicker
              placeholder="有效期开始时间"
              type="datetime"
              v-model="obj.DateBegin"
              style="width: 100%"
            ></DatePicker>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Type" label="结束时间">
            <DatePicker
              placeholder="有效期结束时间"
              type="datetime"
              v-model="obj.DateEnd"
              style="width: 100%"
            ></DatePicker>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="AppID" label="商户APPID">
            <Input type="text" v-model="obj.AppID"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="NotifyUrl" label="回调地址">
            <Input type="text" v-model="obj.NotifyUrl"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16" v-if="obj.Type == 1">
        <Col span="12">
          <FormItem prop="CharSet" label="字符编码">
            <Input type="text" v-model="obj.CharSet"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="SignType" label="签名方式">
            <Input type="text" v-model="obj.SignType"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16" v-if="obj.Type == 1">
        <Col span="12">
          <FormItem prop="PrivateKey" label="私钥">
            <Input type="text" v-model="obj.PrivateKey"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="PublicKey" label="公钥">
            <Input type="text" v-model="obj.PublicKey"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16" v-if="obj.Type == 1">
        <Col span="12">
          <FormItem prop="GatewayUrl" label="网关地址">
            <Input type="text" v-model="obj.GatewayUrl"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="ReturnUrl" label="返回地址">
            <Input type="text" v-model="obj.ReturnUrl"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16" v-if="obj.Type == 1">
        <Col span="12">
          <FormItem prop="QuitUrl" label="退出地址">
            <Input type="text" v-model="obj.QuitUrl"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16" v-if="obj.Type != 1">
        <Col span="12">
          <FormItem prop="MCHID" label="商户号">
            <Input type="text" v-model="obj.MCHID"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Key" label="微信Key">
            <Input type="text" v-model="obj.Key"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16" v-if="obj.Type != 1">
        <Col span="12">
          <FormItem prop="APPSecret" label="微信密码">
            <Input type="text" v-model="obj.APPSecret"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="SSLCertPath" label="证书地址">
            <Input type="text" v-model="obj.SSLCertPath"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16" v-if="obj.Type != 1">
        <Col span="12">
          <FormItem prop="APPSecret" label="微信密码">
            <Input type="text" v-model="obj.APPSecret"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="SSLCertPassword" label="证书密码">
            <Input type="text" v-model="obj.SSLCertPassword"></Input>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button
        v-if="isEdit || isAdd"
        type="primary"
        @click="handleSave"
        :loading="saveLoading"
      >
        {{ !saveLoading ? "保存" : "保存中..." }}
      </Button>
    </div>
  </Modal>
</template>
<script>
import bizEnum from "@/libs/bizEnum";
import api from "@/api";
export default {
  name: "pay-config-edit",
  data() {
    return {
      usechannelArr: [],
      isShow: false,
      isEdit: false,
      isAdd: true,
      saveLoading: false,
      loading: false,
      obj: {},
    };
  },
  methods: {
    async handleSave() {
      const _this = this;
      try {
        _this.saveLoading = true;
        _this.obj.Channel = _this.usechannelArr.toString();
        const response = await api.SavePayConfig({
          entity: _this.obj,
        });
        if (response.Status === 100) {
          _this.$Message.success("保存成功！");
          _this.saveLoading = false;
          _this.isShow = false;
          this.$emit("on-save", response.Data);
        } else {
          _this.saveLoading = false;
          _this.isShow = false;
          _this.$Message.error(response.Msg);
        }
      } catch (error) {
        _this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.saveLoading = false;
      }
    },
  },
  computed: {
    PayConfigTypeEnum() {
      return bizEnum.PayConfigTypeEnum;
    },
  },
  components: {},
};
</script>
